import React from 'react';

const LoginPage = () => {
  const handleLogin = () => {
    // Redireciona para o endpoint de login no backend
    window.location.href = '/api/login';  // Chama o backend para iniciar o fluxo de login
    //window.location.href = 'http://localhost:8000/login';  // Chama o backend para iniciar o fluxo de login
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h2>Login com Office 365</h2>
      <button onClick={handleLogin}>Fazer Login com Office 365</button>
    </div>
  );
};

export default LoginPage;
