import React, { useState } from 'react';
import axios from 'axios';

function CalculadoraPJ() {
  const [estimativa, setEstimativa] = useState({
    horas_trabalhadas: 168,
    valor_hora_pago: 120.88,
    valor_hora_recebido: 201.00,
    com_ferias: false,
    aviso_com_receita: false,
    sem_aviso: true,
  });

  const [resultado, setResultado] = useState(null);
  const [simulacao, setSimulacao] = useState(null);
  const [sugestoes, setSugestoes] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEstimativa(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSimulacao = async () => {
    try {
      //const response = await axios.post('http://localhost:8000/calcular/', estimativa);
      const response = await axios.post('/api/calcular/', estimativa);

      setSimulacao(response.data);
      gerarSugestoes(response.data); // Gera sugestões com base na simulação
    } catch (error) {
      console.error('Erro ao calcular simulação', error);
    }
  };

  const gerarSugestoes = (data) => {
    const sugestoesGeradas = [];
    
    if (data.margem_anual && parseFloat(data.margem_anual) < 10) {
      sugestoesGeradas.push('Considere aumentar o valor hora recebido para melhorar a margem de lucro.');
    }
    
    if (data.lucro_anual && parseFloat(data.lucro_anual.replace(/[^0-9.-]+/g,"")) < 10000) {
      sugestoesGeradas.push('O lucro anual está abaixo do esperado. Avalie a possibilidade de reduzir os custos operacionais.');
    }
    
    setSugestoes(sugestoesGeradas);
  };

  return (
    <div className="CalculadoraPJ" style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      <h1>Calculadora Prestador</h1>

      <form style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <div style={{ flex: '1 1 45%', marginBottom: '20px' }}>
          <h3>Itens de Cálculo</h3>
          <label>
            <strong>Horas Trabalhadas:</strong><br />
            <input
              type="number"
              name="horas_trabalhadas"
              value={estimativa.horas_trabalhadas}
              onChange={handleChange}
              style={{ width: '80px', padding: '8px' }}
            />
            <small>Exemplo: 168 horas. Você pode alterar esse valor.</small>
          </label>
          <br /><br />

          <label>
            <strong>Valor Hora Pago:</strong><br />
            <input
              type="number"
              name="valor_hora_pago"
              value={estimativa.valor_hora_pago}
              onChange={handleChange}
              style={{ width: '80px', padding: '8px' }}
            />
            <small>Exemplo: R$ 120,88. Você pode alterar esse valor.</small>
          </label>
          <br /><br />

          <label>
            <strong>Valor Hora Recebido:</strong><br />
            <input
              type="number"
              name="valor_hora_recebido"
              value={estimativa.valor_hora_recebido}
              onChange={handleChange}
              style={{ width: '80px', padding: '8px' }}
            />
            <small>Exemplo: R$ 201,00. Você pode alterar esse valor.</small>
          </label>
        </div>

        <div style={{ flex: '1 1 45%', marginBottom: '20px' }}>
          <h3>Opções</h3>
          <label>
            <input
              type="checkbox"
              name="com_ferias"
              checked={estimativa.com_ferias}
              onChange={handleChange}
            />
            <strong>Incluir Férias</strong><br />
            <small>Se marcado, o cálculo vai incluir o custo das férias (84 horas).</small>
          </label>
          <br /><br />

          <label>
            <input
              type="checkbox"
              name="aviso_com_receita"
              checked={estimativa.aviso_com_receita}
              onChange={handleChange}
            />
            <strong>Aviso com Receita</strong><br />
            <small>Se marcado, considera o aviso prévio (40 horas) como tempo faturado.</small>
          </label>
          <br /><br />

          <label>
            <input
              type="checkbox"
              name="sem_aviso"
              checked={estimativa.sem_aviso}
              onChange={handleChange}
            />
            <strong>Sem Aviso Prévio</strong><br />
            <small>Se marcado, não considera o aviso prévio, ou seja, sem horas e sem receita.</small>
          </label>
        </div>

        <div style={{ flexBasis: '100%', textAlign: 'center', margin: '20px 0' }}>
          <button type="button" onClick={handleSimulacao} style={{ padding: '10px 20px', fontSize: '16px' }}>Simular Cenário</button>
        </div>
      </form>

      {simulacao && (
        <div style={{ marginTop: '20px' }}>
          <h2>Resultado da Simulação:</h2>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ flex: 1, textAlign: 'center' }}>
              <h3>Mensal</h3>
              <p><strong>Receita Mensal:</strong> {simulacao.receita_mensal}</p>
              <p><strong>Lucro Mensal:</strong> {simulacao.lucro_mensal}</p>
              <p><strong>Margem Mensal:</strong> {simulacao.margem_mensal}</p>
            </div>

            <div style={{ flex: 1, textAlign: 'center' }}>
              <h3>Anual</h3>
              <p><strong>Receita Anual:</strong> {simulacao.receita_anual}</p>
              <p><strong>Lucro Anual:</strong> {simulacao.lucro_anual}</p>
              <p><strong>Margem Anual:</strong> {simulacao.margem_anual}</p>
            </div>
          </div>

          {/* Exibição de Sugestões */}
          {sugestoes && sugestoes.length > 0 && (
            <div style={{ marginTop: '20px', backgroundColor: '#f9f9f9', padding: '10px', borderRadius: '5px' }}>
              <h3>Sugestões de Melhoria:</h3>
              <ul>
                {sugestoes.map((sugestao, index) => (
                  <li key={index}>{sugestao}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      {/* Seção de Entendimento Geral e Explicações */}
      <div style={{ marginTop: '40px' }}>
        <h2>Entendimento Geral e Explicações</h2>
        <p>
          <strong>Férias:</strong> Se a opção de incluir férias estiver marcada, o sistema considera um custo adicional de 84 horas, correspondendo a 15 dias corridos de férias, no cálculo anual.
        </p>
        <p>
          <strong>Aviso Prévio:</strong> O aviso prévio pode impactar os cálculos de duas maneiras:
          <ul>
            <li><strong>Com Receita:</strong> Se a opção "Aviso com Receita" estiver marcada, 40 horas adicionais são incluídas na receita e no lucro, distribuídas ao longo dos 12 meses.</li>
            <li><strong>Sem Aviso Prévio:</strong> Se a opção "Sem Aviso Prévio" estiver marcada, o sistema não deduz as 40 horas, pois não haverá aviso, receita ou pagamento adicional. Se a opção "Sem Aviso Prévio" não estiver marcada, o sistema reduzirá 40 horas do montante anual e mensal, distribuídas ao longo dos 12 meses.</li>
          </ul>
        </p>
        <p>
          <strong>Receita e Lucro:</strong> Os cálculos de receita e lucro são apresentados tanto em bases mensais quanto anuais, permitindo uma visão clara do impacto das variáveis ao longo do tempo.
        </p>
      </div>
    </div>
  );
}

export default CalculadoraPJ;
