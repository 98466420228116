import React, { useState } from 'react';
import axios from 'axios';

function CalculadoraCLT() {

  const [salarioBruto, setSalarioBruto] = useState(5000.00);
  const [dependentes, setDependentes] = useState(0);
  const [tipoRescisao, setTipoRescisao] = useState('semJustaCausa');
  const [inssPatronal, setInssPatronal] = useState(20.0);
  const [fgts, setFgts] = useState(8.0);
  const [terceiros, setTerceiros] = useState(5.8);
  const [outrosEncargos, setOutrosEncargos] = useState(0.5);
  const [valeRefeicao, setValeRefeicao] = useState(583.0);
  const [assistenciaMedica, setAssistenciaMedica] = useState(954.0);
  const [auxilioCreche, setAuxilioCreche] = useState(0.0);
  const [valorHoraPagoCliente, setValorHoraPagoCliente] = useState(150.00);
  const [resultado, setResultado] = useState(null);


  const handleCalculate = async () => {
    try {
      const response = await axios.post('/api/calcular_clt/', {
      //const response = await axios.post('http://localhost:8000/calcular_clt/', {
        salario_bruto: salarioBruto,
        dependentes: dependentes,
        tipo_rescisao: tipoRescisao,
        inss_patronal: inssPatronal,
        fgts: fgts,
        terceiros: terceiros,
        outros_encargos: outrosEncargos,
        vale_refeicao: valeRefeicao,
        assistencia_medica: assistenciaMedica,
        auxilio_creche: auxilioCreche,
        valor_hora_pago_cliente: valorHoraPagoCliente // Novo campo enviado
      });
      setResultado(response.data);
    } catch (error) {
      console.error('Erro ao calcular', error);
    }
  };

  const formatCurrency = (value) => {
    return value ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00';
  };

  return (
    <div className="CalculadoraCLT" style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      <h1>Calculadora CLT</h1>

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        <div style={{ flex: '1 1 200px' }}>
          <label>
            <strong>Salário Bruto:</strong><br />
            <input
              type="number"
              value={salarioBruto}
              onChange={(e) => setSalarioBruto(parseFloat(e.target.value))}
              style={{ width: '100%', padding: '8px' }}
            />
          </label>
        </div>

        <div style={{ flex: '1 1 200px' }}>
          <label>
            <strong>Número de Dependentes:</strong><br />
            <input
              type="number"
              value={dependentes}
              onChange={(e) => setDependentes(parseInt(e.target.value, 10))}
              style={{ width: '100%', padding: '8px' }}
            />
          </label>
        </div>

        <div style={{ flex: '1 1 200px' }}>
          <label>
            <strong>Tipo de Rescisão:</strong><br />
            <select value={tipoRescisao} onChange={(e) => setTipoRescisao(e.target.value)} style={{ width: '100%', padding: '8px' }}>
              <option value="semJustaCausa">Sem Justa Causa</option>
              <option value="comJustaCausa">Com Justa Causa</option>
              <option value="pedidoDemissao">Pedido de Demissão</option>
            </select>
          </label>
        </div>

        <div style={{ flex: '1 1 200px' }}>
          <label>
            <strong>INSS Patronal (%):</strong><br />
            <input
              type="number"
              value={inssPatronal}
              onChange={(e) => setInssPatronal(parseFloat(e.target.value))}
              style={{ width: '100%', padding: '8px' }}
            />
          </label>
        </div>

        <div style={{ flex: '1 1 200px' }}>
          <label>
            <strong>FGTS (%):</strong><br />
            <input
              type="number"
              value={fgts}
              onChange={(e) => setFgts(parseFloat(e.target.value))}
              style={{ width: '100%', padding: '8px' }}
            />
          </label>
        </div>

        <div style={{ flex: '1 1 200px' }}>
          <label>
            <strong>Imposto de TERCEIROS (%):</strong><br />
            <input
              type="number"
              value={terceiros}
              onChange={(e) => setTerceiros(parseFloat(e.target.value))}
              style={{ width: '100%', padding: '8px' }}
            />
          </label>
        </div>

        <div style={{ flex: '1 1 200px' }}>
          <label>
            <strong>Outros Encargos (%):</strong><br />
            <input
              type="number"
              value={outrosEncargos}
              onChange={(e) => setOutrosEncargos(parseFloat(e.target.value))}
              style={{ width: '100%', padding: '8px' }}
            />
          </label>
        </div>

        <div style={{ flex: '1 1 200px' }}>
          <label>
            <strong>Vale Refeição (R$):</strong><br />
            <input
              type="number"
              value={valeRefeicao}
              onChange={(e) => setValeRefeicao(parseFloat(e.target.value))}
              style={{ width: '100%', padding: '8px' }}
            />
          </label>
        </div>

        <div style={{ flex: '1 1 200px' }}>
          <label>
            <strong>Assistência Médica (R$):</strong><br />
            <input
              type="number"
              value={assistenciaMedica}
              onChange={(e) => setAssistenciaMedica(parseFloat(e.target.value))}
              style={{ width: '100%', padding: '8px' }}
            />
          </label>
        </div>

        <div style={{ flex: '1 1 200px' }}>
          <label>
            <strong>A. CRECHE (R$):</strong><br />
            <input
              type="number"
              value={auxilioCreche}
              onChange={(e) => setAuxilioCreche(parseFloat(e.target.value))}
              style={{ width: '100%', padding: '8px' }}
            />
          </label>
        </div>

        <div style={{ flex: '1 1 200px' }}>
          <label>
            <strong>Valor Hora Pago pelo Cliente (R$):</strong><br />
            <input
              type="number"
              value={valorHoraPagoCliente}
              onChange={(e) => setValorHoraPagoCliente(parseFloat(e.target.value))}
              style={{ width: '100%', padding: '8px' }}
            />
          </label>
        </div>

      </div>

      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <button onClick={handleCalculate} style={{ padding: '10px 20px', fontSize: '16px' }}>Calcular</button>
      </div>

  
      {resultado && (
        <div style={{ marginTop: '20px', padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
          <h2 style={{ borderBottom: '2px solid #333', paddingBottom: '10px' }}>Resumo Final</h2>

          {/* Margem de Lucro */}
          <section style={{ marginBottom: '20px' }}>
            <h3 style={{ color: '#007BFF' }}>Margem de Lucro</h3>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <tbody>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>Margem (%):</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{resultado.margem.toFixed(2)}%</td>
                </tr>
              </tbody>
            </table>
          </section>

          {/* Custo Total */}
          <section style={{ marginBottom: '20px' }}>
            <h3 style={{ color: '#007BFF' }}>Custo Total Mensal com Provisões</h3>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <tbody>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>Custo Total:</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.custo_total)}</td>
                </tr>
              </tbody>
            </table>
          </section>

          {/* Valor Hora Base */}
          <section style={{ marginBottom: '20px' }}>
            <h3 style={{ color: '#007BFF' }}>Valor Hora Base</h3>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <tbody>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>Valor Hora Base:</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.valor_hora_base)}</td>
                </tr>
              </tbody>
            </table>
          </section>

          {/* Provisões */}
          <section style={{ marginBottom: '20px' }}>
            <h3 style={{ color: '#007BFF' }}>Provisões</h3>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <tbody>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>13º Salário Proporcional:</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.decimo_terceiro)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>Férias Proporcionais:</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.ferias)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>FGTS 13º Salário Proporcional:</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.fgts_decimo_terceiro)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>FGTS Férias Proporcionais:</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.fgts_ferias)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>Multa do FGTS (40%):</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.multa_fgts)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>Aviso Prévio (Indenizado):</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.rescisao_provisionada)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>Subtotal Provisões:</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.subtotal_provisoes)}</td>
                </tr>
              </tbody>
            </table>
          </section>

          {/* Pagamentos Mensais */}
          <section>
            <h3 style={{ color: '#007BFF' }}>Pagamentos Mensais</h3>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <tbody>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>Salário Bruto:</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.salario_bruto)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>INSS (Empregado):</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.inss_empregado)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>IRRF:</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.irrf)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>INSS Patronal (20%):</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.inss_patronal)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>FGTS (8%):</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.fgts_valor)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>Imposto de Terceiros (5.8%):</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.terceiros_valor)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>RAT (0.5%):</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.rat_valor)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>Vale Refeição:</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.vale_refeicao)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>Assistência Médica:</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.assistencia_medica)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>Auxílio Creche:</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.auxilio_creche)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>Salário Líquido (Recebido pelo Profissional):</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.salario_liquido)}</td>
                </tr>
                <tr>
                  <td style={{ width: '70%', textAlign: 'left' }}><strong>Subtotal Pagamentos Mensais:</strong></td>
                  <td style={{ width: '30%', textAlign: 'right' }}>{formatCurrency(resultado.subtotal_pagamentos_mensais)}</td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      )}
    
  


    </div>
  );
}

export default CalculadoraCLT;

