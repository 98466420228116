import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import CalculadoraPJ from './pages/CalculadoraPJ';
import CalculadoraCLT from './pages/CalculadoraCLT';
import LoginPage from './pages/LoginPage';


// Botao Logout para ser ajustado.
// <button onClick={handleLogout} style={{ marginLeft: '10px' }}>Logout</button>

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  // Função para verificar o estado de login
  const checkLoginStatus = async () => {
    try {
      //const response = await fetch('http://localhost:8000/status', {
      const response = await fetch('/api/status', {
        method: 'GET',
        credentials: 'include'  // Inclui cookies na requisição
      });


      const data = await response.json();
      if (data.logged_in) {
        // Se o usuário estiver logado, salva no localStorage
        localStorage.setItem('USER', JSON.stringify(data.USER));
        setIsLoggedIn(true);
      } else {
        localStorage.removeItem('USER');
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error('Erro ao verificar login:', error);
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('USER');  // Remover o token ou dados de login
    window.location.href = '/api/logout';  // Redirecionar para o logout no backend
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Sistema de Calculadoras</h1>
      <nav>
        {isLoggedIn ? (
          <>
            <Link to="/calculadora-pj" style={{ marginRight: '10px' }}>Calculadora Prestador</Link>
            <Link to="/calculadora-clt" style={{ marginRight: '10px' }}>Calculadora CLT</Link>
            
          </>
        ) : (
          <Link to="/login">Login</Link>
        )}
      </nav>

      <Routes>
        {isLoggedIn ? (
          <>
            <Route path="/calculadora-pj" element={<CalculadoraPJ />} />
            <Route path="/calculadora-clt" element={<CalculadoraCLT />} />
          </>
        ) : (
          <Route path="/login" element={<LoginPage />} />
        )}
      </Routes>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
